<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>和我们，做有挑战的事！</div>
        </div>
      </div>
    </div>
    <div class="container-lg px-4 content py-5">
      <div class="col-12">
        <a
          @click="$router.go(-1)"
          role="button"
          class="btn btn-outline-primary mb-3"
        >
          返回列表
        </a>
      </div>
      <div class="col-12 title fs-1 fw-bold">品牌公关</div>
      <div class="mt-3">
        <div class="badge border text-primary border-primary">市场</div>
        <div class="ms-2 badge border text-primary border-primary">
          社会招聘
        </div>
      </div>
      <div class="description mt-3">
        <div class="fs-4 fw-bold my-4">岗位描述</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              品牌：根据公司品牌规划，完善品牌物料，参与各类品牌传播活动的策划和执行；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              公关：根据公司年度公关策略，负责内容的搜集整理，撰写新闻稿和宣传文案，并利用媒体资源通过多种渠道进行传播，提升公司在业界的声誉；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              舆情：利用监测平台，监控敏感舆情，根据需要搜集、整理和分析舆情信息，协助做好负面信息拦截和媒体沟通支持，提升公司美誉度；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              奖项：负责公司在投资领域中各类奖项的接洽、申报和论坛活动参与；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              新媒体运营：熟悉新媒体的运营模式（微信公众号、视频号、知乎、B站等），微信公众号文章排版和发布，视频号内容策划和运营；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              根据业务需要，进行供应商搜集、整理、接洽，做好供应商管理。
            </li>
          </ul>
        </div>
      </div>

      <div class="requirement mt-3">
        <div class="fs-4 fw-bold my-4">岗位要求</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              统招本科及以上学历，市场营销、广告、新闻传播学、公共关系等专业优先；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              有财经类媒体或者金融行业实习经验优先；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              对金融科技、一体化解决方案有深刻认知和理解；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              具有优秀的文字能力，有一定的设计美感；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              具有较强的沟通能力、社会活动能力、团队协作能力。
            </li>
          </ul>
        </div>
      </div>

      <div class="location mt-3">
        <div class="fs-4 fw-bold my-4">工作城市</div>
        <div class="mt-2">
          <span> 上海 </span>
          <span> 北京 </span>
          <span> 深圳 </span>
          <span> 成都 </span>
          <span> 徐州 </span>
        </div>
      </div>

      <div class="mt-3">
        <div class="fs-4 fw-bold my-4">联系方式</div>
        <div class="mb-2">简历请发送至邮箱：recruit@ft.tech</div>
        <img
          src="../../assets/img/招聘求职.jpg"
          align="center"
        />
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job9",
};
</script>
<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.container-lg {
  border-radius: 3rem;
}
a.btn {
  border-radius: 2rem;
}
img {
  width: 100px;
}
.cover-text > div {
  font-size: 3rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
}
</style>
